import { useCallback, useEffect, useState } from "react";

import FormFooter from "components/FormDefault/Footer";
import FormGroup from "components/FormDefault/Group";
import Select from "components/select/Select";
import TagInput from "components/TagInput";
import Button from "ds/components/Button";
import useTypedContext from "hooks/useTypedContext";
import { AzureIntegration } from "types/generated";
import formStyles from "components/FormDefault/styles.module.css";
import { DEFAULT_SPACE_NAME } from "views/constants";
import SpaceOption from "components/select/SpaceOption";
import { SpacesContext } from "views/Account/SpacesProvider";
import { SelectOption } from "ds/components/Select/types";

import styles from "./styles.module.css";

type AzureIntegrationFormProps =
  | {
      onCreate: (
        name: string,
        tenantId: string,
        defaultSubscriptionId: string,
        labels: string[],
        space?: string
      ) => void;
      onCancel: () => void;
      integration?: undefined;
      loading: boolean;
    }
  | {
      onUpdate: (
        id: AzureIntegration["id"],
        name: string,
        defaultSubscriptionId: string,
        labels: string[],
        space?: string
      ) => void;
      onCancel: () => void;
      integration: AzureIntegration;
      loading: boolean;
    };

const AzureIntegrationForm = (props: AzureIntegrationFormProps) => {
  const { manageableSpaces, manageableSpacesSelectOptions } = useTypedContext(SpacesContext);
  const { onCancel, loading, integration } = props;
  const isEditing = !!integration;

  const [name, setName] = useState(integration?.name || "");
  const [tenantId, setTenantId] = useState(integration?.tenantId || "");
  const [defaultSubscriptionId, setDefaultSubscriptionId] = useState<string>(
    integration?.defaultSubscriptionId || ""
  );
  const [labels, setLabels] = useState<string[]>(integration?.labels || []);
  const [canAdd, setCanAdd] = useState(false);
  const [space, setSpace] = useState(
    integration
      ? integration?.spaceDetails.id
      : manageableSpaces.find((s) => s.id === DEFAULT_SPACE_NAME)?.id || manageableSpaces[0].id
  );

  const onSubmit = useCallback(
    (e: React.FormEvent) => {
      e.preventDefault();

      if (props.integration) {
        props.onUpdate(props.integration.id, name, defaultSubscriptionId, labels, space);
      } else {
        props.onCreate(name, tenantId, defaultSubscriptionId, labels, space);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [props.integration, name, defaultSubscriptionId, labels, tenantId, space]
  );

  useEffect(() => {
    setCanAdd(!!name && !!tenantId && !loading);
  }, [name, tenantId, loading]);

  const handleInputChange =
    (setter: React.Dispatch<React.SetStateAction<string>>) =>
    (e: React.ChangeEvent<HTMLInputElement>) =>
      setter(e.target.value);

  return (
    <form onSubmit={onSubmit}>
      <FormGroup
        labelClassName={styles.formLabel}
        labelText="Name:"
        type="text"
        placeholder="Name (required)"
        value={name}
        onChange={handleInputChange(setName)}
      />
      {!isEditing && (
        <FormGroup
          labelClassName={styles.formLabel}
          labelText="Tenant ID:"
          type="text"
          placeholder="Azure AD Tenant ID (required)"
          value={tenantId}
          onChange={handleInputChange(setTenantId)}
        />
      )}
      <FormGroup
        labelClassName={styles.formLabel}
        labelText="Default Subscription ID:"
        type="text"
        placeholder="The default Azure subscription to use if not overridden at the stack level (optional)"
        value={defaultSubscriptionId}
        onChange={handleInputChange(setDefaultSubscriptionId)}
      />
      {manageableSpacesSelectOptions && (
        <FormGroup labelText="Space:" labelClassName={styles.formLabel}>
          <Select
            className={formStyles.input}
            onChange={({ value }: SelectOption) => setSpace(value as string)}
            options={manageableSpacesSelectOptions}
            value={manageableSpacesSelectOptions.find((option) => option.value === space)}
            optionComponent={SpaceOption}
          />
        </FormGroup>
      )}
      <FormGroup labelText="Labels:" labelClassName={styles.formLabel}>
        <TagInput entity="Label" placeholder="Add labels" tags={labels} setTags={setLabels} />
      </FormGroup>
      <FormFooter top>
        <Button onClick={() => onCancel()} variant="secondary" className={styles.cancelButton}>
          Cancel
        </Button>
        <Button type="submit" variant="primary" disabled={!canAdd}>
          {isEditing ? "Edit Integration" : "Create Integration"}
        </Button>
      </FormFooter>
    </form>
  );
};

export default AzureIntegrationForm;
