import { useMutation, useQuery } from "@apollo/client";
import { ChangeEvent, useEffect, useId, useRef, useState } from "react";

import { AccountContext } from "views/AccountWrapper";
import useTitle from "hooks/useTitle";
import useTypedContext from "hooks/useTypedContext";
import FlashContext from "components/FlashMessages/FlashContext";
import PageLoading from "components/loading/PageLoading";
import NotFoundPage from "components/error/NotFoundPage";
import useErrorHandle from "hooks/useErrorHandle";
import Typography from "ds/components/Typography";
import Box from "ds/components/Box";
import Slider from "ds/components/Slider";
import FormToggleField from "ds/components/Form/ToggleField";
import Input from "ds/components/Input";
import useAnalytics from "hooks/useAnalytics";
import { AnalyticsPageOrganization } from "hooks/useAnalytics/pages/organization";

import LimitsPageLayout from "./PageLayout";
import { GET_LIMIT, UPDATE_LIMIT } from "./gql";
import styles from "./styles.module.css";

const DEFAULT_TURN_ON_LIMIT = 500;
const MIN_LIMIT = 0;
const MAX_LIMIT = 500;

const Limits = () => {
  const { accountName } = useTypedContext(AccountContext);
  const { onError, reportSuccess } = useTypedContext(FlashContext);
  const [isActive, setIsActive] = useState(false);
  const [limit, setLimit] = useState(DEFAULT_TURN_ON_LIMIT);
  const timeout = useRef<NodeJS.Timeout>();
  const inputRef = useRef<HTMLInputElement>(null);
  useTitle(`Organization Settings · Limits · ${accountName}`);

  const switchToggleTitleId = useId();

  const trackSegmentAnalyticsEvent = useAnalytics({
    page: AnalyticsPageOrganization.OrganizationLimits,
  });

  const { loading, data, error } = useQuery<{ vcsEventTriggeredRunsLimit: number }>(GET_LIMIT, {
    onError,
    onCompleted: (data) => {
      if (data && typeof data.vcsEventTriggeredRunsLimit === "number") {
        const currentLimit = data.vcsEventTriggeredRunsLimit;

        if (!isActive) {
          setIsActive(true);
        }

        if (currentLimit !== limit) {
          setLimit(currentLimit);
        }
      }
    },
  });

  const [updateLimit, { loading: updateLimitLoading }] = useMutation(UPDATE_LIMIT, {
    refetchQueries: [GET_LIMIT],
  });

  const handleActiveChange = (active: boolean) => {
    updateLimit({ variables: { limit: active ? DEFAULT_TURN_ON_LIMIT : null } })
      .then(({ data }) => {
        if (data) {
          if (!active) {
            setLimit(DEFAULT_TURN_ON_LIMIT);
          }
          setIsActive(active);

          const message = `Limit has been turned ${active ? "on" : "off"}`;
          reportSuccess({ message });
          trackSegmentAnalyticsEvent(message);
        }
      })
      .catch(onError);
  };

  const handleLimitChange = (value: number) => {
    if (value !== data?.vcsEventTriggeredRunsLimit) {
      updateLimit({ variables: { limit: value } })
        .then(({ data }) => {
          if (data) {
            const message = "Limit has been updated";
            reportSuccess({ message });
            trackSegmentAnalyticsEvent(message);
          }
        })
        .catch(onError);
    }
  };

  const handleInputLimitChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = Number(e.target.value);

    if (value >= MIN_LIMIT && value <= MAX_LIMIT) {
      setLimit(value);

      if (timeout.current) {
        clearTimeout(timeout.current);
      }

      timeout.current = setTimeout(() => {
        if (inputRef.current) {
          inputRef.current.dataset.focus = "true";
        }

        handleLimitChange(value);
      }, 300);
    }
  };

  useEffect(() => {
    return () => {
      if (timeout.current) {
        clearTimeout(timeout.current);
      }
    };
  }, []);

  useEffect(() => {
    if (!updateLimitLoading && inputRef.current && inputRef.current.dataset.focus) {
      inputRef.current.focus();
      delete inputRef.current.dataset.focus;
    }
  }, [updateLimitLoading]);

  const ErrorContent = useErrorHandle(error);

  if (ErrorContent) {
    return ErrorContent;
  }

  if (loading && !data) {
    return <PageLoading />;
  }

  if (!data) {
    return <NotFoundPage />;
  }

  return (
    <LimitsPageLayout>
      <Box direction="column" limitWidth="medium" fullWidth>
        <Box align="start" justify="between" fullWidth>
          <FormToggleField
            variant="switch"
            onChange={handleActiveChange}
            checked={isActive}
            title="Limit of runs created for a VCS event"
            description="Set a maximum number of runs triggered by a VCS event for account."
            titleId={switchToggleTitleId}
          />
        </Box>

        {isActive && (
          <Box padding="large 0" direction="column">
            <Box gap="large">
              <Box direction="column" className={styles.sliderWrapper} grow="1">
                <Slider
                  hasError={limit === MIN_LIMIT}
                  tooltip
                  disabled={updateLimitLoading}
                  min={MIN_LIMIT}
                  max={MAX_LIMIT}
                  onChange={setLimit}
                  onChangeComplete={handleLimitChange}
                  value={limit}
                  aria-labelledby={switchToggleTitleId}
                />
              </Box>
              <Input
                ref={inputRef}
                aria-label="Update limit"
                className={styles.sliderInput}
                type="number"
                value={limit}
                onChange={handleInputLimitChange}
                min={MIN_LIMIT}
                max={MAX_LIMIT}
                disabled={updateLimitLoading}
              />
            </Box>
            {limit === 0 && (
              <Typography variant="p-body3" tag="p" color="danger" margin="medium 0 0 0">
                If you disable VCS-driven run creation, you will only be able to trigger runs
                manually. In most cases, this is not advised.
              </Typography>
            )}
          </Box>
        )}
      </Box>
    </LimitsPageLayout>
  );
};

export default Limits;
