import React, { useState } from "react";
import { create, show } from "@ebay/nice-modal-react";

import SpaceliftLogo from "ds/components/SpaceliftLogo";
import InputText from "components/input/InputText";
import ModalSimple from "ds/components/ModalSimple";
import ModalHeader from "ds/components/Modal/Header";
import ModalContent from "ds/components/Modal/Content";
import Typography from "ds/components/Typography";
import Button from "ds/components/Button";

import { extractAppURL } from "./utils";
import styles from "./styles.module.css";

type LoginListModalProps = {
  logins: string[];
};

const LoginListModal = create(function LoginListModal({ logins }: LoginListModalProps) {
  const [login, setLogin] = useState("");

  const appURL = extractAppURL(window.location.hostname);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => setLogin(e.target.value);

  return (
    <ModalSimple size="large">
      <ModalHeader title="Change account" />
      <ModalContent gap="medium">
        <Typography tag="p" variant="p-body2">
          Use one of the recent accounts
        </Typography>
        <ul className={styles.modalList}>
          {logins.map((login) => (
            <li key={`login-${login}`}>
              <a className={styles.modalListLink} href={`https://${login}.${appURL}`}>
                <SpaceliftLogo className={styles.modalListLogo} short />

                {`${login}.${appURL}`}
              </a>
            </li>
          ))}
        </ul>
        <Typography tag="p" variant="p-body2">
          or enter GitHub user / organization name
        </Typography>
        <InputText
          className={styles.modalInput}
          value={login}
          onChange={handleInputChange}
          bordered
        />

        <Button variant="primary" href={`https://${login}.${appURL}`} disabled={!login} fullWidth>
          Switch account
        </Button>
      </ModalContent>
    </ModalSimple>
  );
});

export const showLoginListModal = (props: LoginListModalProps) => show(LoginListModal, props);
