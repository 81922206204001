import SpaceliftLogo from "ds/components/SpaceliftLogo";
import useSpaceliftLoginsCookie from "hooks/useSpaceliftLoginsCookie";
import TextEllipsis from "ds/components/TextEllipsis";
import DropdownMenuItem from "ds/components/DropdownMenu/Item";
import DropdownSubmenu from "ds/components/DropdownMenu/Submenu";
import Icon from "ds/components/Icon";
import { ChevronRight } from "components/icons/generated";
import DropdownMenuDivider from "ds/components/DropdownMenu/Divider";

import { showLoginListModal } from "./Modal";
import { extractAppURL } from "./utils";
import styles from "./styles.module.css";

type LoginListProps = {
  accountName: string;
  onModalShow?: () => void;
};

function LoginList({ accountName, onModalShow }: LoginListProps) {
  const { cookieLogins } = useSpaceliftLoginsCookie();
  const appURL = extractAppURL(window.location.hostname);

  const logins = cookieLogins.filter((item: string) => item !== accountName);

  const handleModalShow = () => {
    onModalShow?.();
    showLoginListModal({ logins });
  };

  if (!logins || logins?.length === 0) return null;

  return (
    <>
      <DropdownSubmenu
        className={styles.menu}
        placement="right top"
        triggerComponent={
          <DropdownMenuItem className={styles.trigger}>
            Change account
            <Icon src={ChevronRight} />
          </DropdownMenuItem>
        }
      >
        {logins.map((login: string) => (
          <DropdownMenuItem key={`login-${login}`} href={`https://${login}.${appURL}`}>
            <SpaceliftLogo className={styles.listItemLogo} short />

            <TextEllipsis tooltip={login}>
              {(props) => <span {...props}>{login}</span>}
            </TextEllipsis>
          </DropdownMenuItem>
        ))}

        <DropdownMenuItem onAction={handleModalShow} className={styles.addAccountItem}>
          + Add account
        </DropdownMenuItem>
      </DropdownSubmenu>
      <DropdownMenuDivider />
    </>
  );
}

export default LoginList;
