import { getApolloClient } from "apollo/client";

import { PAGE_USER_EVENT } from "./gql";
import { getDefaultContext, getDefaultPageProperties } from "./utils";

export const pageUserEvent = () => {
  const apolloClient = getApolloClient();

  if (!apolloClient) {
    return;
  }

  let properties: string;
  let context: string;

  try {
    properties = JSON.stringify(getDefaultPageProperties());
    context = JSON.stringify(getDefaultContext());
  } catch {
    // do nothing, this is the best effort approach
    return;
  }

  apolloClient
    .mutate({
      mutation: PAGE_USER_EVENT,
      variables: {
        properties,
        context,
      },
    })
    .catch(() => {
      // do nothing, this is the best effort approach
    });
};
