import {
  PrivateWorkerMinutes,
  PublicWorkerMinutes,
  Seat,
  Worker,
} from "components/TooltipSnippets";
import Row from "components/row/Row";
import UsageWidget from "components/widget/UsageWidget";
import { TIER_PLANS_LABELS } from "constants/tiers";
import Box from "ds/components/Box";
import LinkButton from "ds/components/Button/LinkButton";
import ButtonNew from "ds/components/Button/New";
import Typography from "ds/components/Typography";
import useTypedFlags from "hooks/useTypedFlags";
import { BillingSubscription, BillingTier, Usage } from "types/generated";

import { showBillingCancelationModal } from "./CancelModal";
import { showBillingUpgradeModal } from "./UpgradeModal";
import styles from "./styles.module.css";

type BillingSubscriptionDetailsProps = {
  usage: Usage;
  billingSubscription?: BillingSubscription;
  tier: BillingTier;
};

const BillingSubscriptionDetails = (props: BillingSubscriptionDetailsProps) => {
  const { billingSubscription, tier, usage } = props;
  const getPrice = (amount: number) => amount && amount / 100;
  const hasActiveSubscription = billingSubscription && billingSubscription.isActive;

  const { useBillingV4 } = useTypedFlags();

  const onChangePlanModalShow = () =>
    showBillingUpgradeModal({
      tier,
    });

  const onCancelModalShow = () => showBillingCancelationModal({ tier });

  return (
    <>
      <Row>
        <Box className={styles.wrapper} direction="column" gap="large">
          <Typography tag="h4" className={styles.title}>
            Your plan
          </Typography>
          <Box direction="column" justify="center" align="center" gap="large">
            <span className={styles.tierName}>{TIER_PLANS_LABELS[tier]}</span>
            {hasActiveSubscription && (
              <>
                <span>{`Your next invoice is $${getPrice(usage.upcomingInvoiceTotalAmount)}`}</span>
                <Box gap="large">
                  {tier === BillingTier.Enterprise && (
                    <ButtonNew variant="primary" onPress={onChangePlanModalShow}>
                      Downgrade
                    </ButtonNew>
                  )}

                  <ButtonNew variant="secondary" onPress={onCancelModalShow}>
                    Cancel
                  </ButtonNew>
                </Box>
              </>
            )}

            {!hasActiveSubscription && !useBillingV4 && (
              <LinkButton variant="primary" to="/billing/form">
                Upgrade
              </LinkButton>
            )}
            <span className={styles.plansInfo}>
              <a
                href="https://spacelift.io/pricing"
                className={styles.plansInfoLink}
                rel="noopener noreferrer"
                target="_blank"
              >
                Check available plans
              </a>
            </span>
          </Box>
        </Box>
        <UsageWidget
          tooltip={<Seat iconClassName={styles.billingIcon} />}
          title="Seats used"
          used={usage.usedSeats}
          unit="seat"
        />
        <UsageWidget
          tooltip={<Worker iconClassName={styles.billingIcon} />}
          title="Workers used"
          used={usage.usedWorkers}
          unit="worker"
        />
      </Row>

      <Row className={styles.minutesRow}>
        <UsageWidget
          tooltip={<PrivateWorkerMinutes iconClassName={styles.billingIcon} />}
          title="Private worker minutes used"
          used={usage.usedPrivateMinutes}
          unit="minute"
        />
        <UsageWidget
          tooltip={<PublicWorkerMinutes iconClassName={styles.billingIcon} />}
          title="Public worker minutes used"
          used={usage.usedPublicMinutes}
          unit="minute"
        />
      </Row>

      {hasActiveSubscription && (
        <Box className={styles.billingInfo} gap="x-large" justify="center" align="center">
          <span>Billing information:</span>
          <LinkButton
            variant="secondary"
            href={billingSubscription?.selfServePortalUrl || ""}
            rel="noopener noreferrer"
            target="_blank"
          >
            Manage payment methods
          </LinkButton>
          <LinkButton
            variant="secondary"
            href={billingSubscription?.selfServePortalUrl || ""}
            rel="noopener noreferrer"
            target="_blank"
          >
            View billing history
          </LinkButton>
          <LinkButton variant="secondary" to="/billing/invoice">
            Invoice details
          </LinkButton>
        </Box>
      )}
    </>
  );
};

export default BillingSubscriptionDetails;
