import { Controller, useFormContext } from "react-hook-form";
import { useCallback, useMemo, useState } from "react";
import { useMutation } from "@apollo/client";

import KeyValue from "components/KeyValue";
import { ArrowLeft, Edit } from "components/icons/generated";
import Box from "ds/components/Box";
import TileWrapper from "ds/components/Tile/Wrapper";
import Typography from "ds/components/Typography";
import { getCountryNameByCode } from "libs/Countries";
import FullScreenModalFooter from "ds/components/FullScreenModal/Footer";
import Button from "ds/components/Button";
import useTypedContext from "hooks/useTypedContext";
import { capitalizeFirstLetter } from "utils/strings";
import FlashContext from "components/FlashMessages/FlashContext";
import { BillingCycleInterval, BillingTier } from "types/generated";
import MetricCard from "components/MetricCard";
import { AnalyticsPageBilling } from "hooks/useAnalytics/pages/billing";
import useAnalytics from "hooks/useAnalytics";
import ButtonIconNew from "ds/components/ButtonIcon/New";
import Toggle from "ds/components/Toggle";

import { UpgradePlanFields } from "../types";
import { BillingUpgradeContext } from "../context";
import { BILLING_SUBSCRIPTION_CREATE } from "./gql";
import BillSummary from "../BillSummary";
import PaymentsPartner from "../../components/PaymentsPartner";
import PoweredByStripeBanner from "../PoweredByStripeBanner";
import BillingDiscountBanner from "../../components/DiscountBanner";
import BillingTwoColumnLayout from "../../components/TwoColumnLayout";
import BillingTwoColumnLayoutLeft from "../../components/TwoColumnLayout/Left";
import BillingTwoColumnLayoutRight from "../../components/TwoColumnLayout/Right";
import BillingAddonFeatureToggles from "../../components/AddonFeatureToggles";
import useStarterPlanInfo from "../../hooks/useStarterPlanInfo";

const BillingUpgradeSummary = () => {
  const { goToPreviousStep, goToNextStep, setPaymentMethod, paymentMethod } =
    useTypedContext(BillingUpgradeContext);
  const {
    control,
    handleSubmit,
    getValues,
    formState: { isSubmitting },
  } = useFormContext<UpgradePlanFields>();
  const { onError } = useTypedContext(FlashContext);

  const [createSubscription] = useMutation(BILLING_SUBSCRIPTION_CREATE, {
    refetchQueries: ["GetBilling", "GetTier", "GetAccountSettings"],
    awaitRefetchQueries: true,
  });

  const formValues = getValues();

  const [initialInterval] = useState(formValues.interval);

  const countryName = useMemo(() => {
    return getCountryNameByCode(formValues.country);
  }, [formValues.country]);

  const cardBrand = paymentMethod?.card?.brand;
  const cardLast4Digits = paymentMethod?.card?.last4;

  const cardInfo =
    cardBrand && cardLast4Digits
      ? `${capitalizeFirstLetter(cardBrand)} •••• ${cardLast4Digits}`
      : null;

  const handleBack = useCallback(() => {
    setPaymentMethod(null);
    goToPreviousStep();
  }, [goToPreviousStep, setPaymentMethod]);

  const submit = useCallback(
    (values: UpgradePlanFields) =>
      createSubscription({
        variables: {
          paymentMethodID: paymentMethod?.id,
          input: {
            address: values.address,
            city: values.city,
            companyName: values.companyName,
            country: values.country,
            email: values.email,
            fullName: values.fullName,
            state: values.state,
            zipCode: values.zipCode,
          },
          tier: BillingTier.V4Starter,
          interval: values.interval,
          addons: values.addons,
        },
      })
        .then(() => {
          goToNextStep();
        })
        .catch(onError),
    [createSubscription, goToNextStep, onError, paymentMethod]
  );

  const isMonthly = formValues.interval === BillingCycleInterval.Monthly;

  const { subscription } = useStarterPlanInfo(formValues.interval, formValues.addons);

  const trackSegmentEvent = useAnalytics({
    page: AnalyticsPageBilling.BillingUpgrade,
  });

  return (
    <>
      <BillingTwoColumnLayout>
        <BillingTwoColumnLayoutLeft>
          <Box direction="column" gap="x-large" fullWidth>
            <TileWrapper gap="x-large" direction="column" grow="0">
              <Box justify="between">
                <Typography tag="h3" variant="p-t5">
                  Starter plan
                </Typography>
                <ButtonIconNew
                  onPress={goToPreviousStep}
                  icon={Edit}
                  variant="ghost"
                  analyticsPage={AnalyticsPageBilling.BillingUpgrade}
                  analyticsTitle="Go back to first step"
                  analyticsProps={{
                    location: "Edit Plan",
                  }}
                >
                  Edit
                </ButtonIconNew>
              </Box>
              <Box direction="column" gap="medium">
                <MetricCard
                  titleColor="primary"
                  title={`${isMonthly ? "Monthly" : "Annual"} subscription`}
                  value={`$ ${subscription}`}
                />
                {initialInterval === BillingCycleInterval.Monthly && (
                  <Controller
                    name="interval"
                    control={control}
                    render={({ field }) => (
                      <BillingDiscountBanner
                        action={
                          <Toggle
                            variant="switch"
                            isSelected={!isMonthly}
                            onChange={() => {
                              field.onChange(
                                isMonthly
                                  ? BillingCycleInterval.Yearly
                                  : BillingCycleInterval.Monthly
                              );

                              trackSegmentEvent("Switch to annual toggle", {
                                value: isMonthly,
                              });
                            }}
                          />
                        }
                      />
                    )}
                  />
                )}
              </Box>
              <BillingAddonFeatureToggles
                interval={formValues.interval}
                addons={formValues.addons}
              />
            </TileWrapper>

            <TileWrapper gap="x-large" direction="column" grow="0">
              <Box justify="between">
                <Typography tag="h3" variant="p-t5">
                  Details
                </Typography>
                <ButtonIconNew
                  onPress={goToPreviousStep}
                  icon={Edit}
                  variant="ghost"
                  analyticsPage={AnalyticsPageBilling.BillingUpgrade}
                  analyticsTitle="Go back to first step"
                  analyticsProps={{
                    location: "Edit Billing Details",
                  }}
                >
                  Edit
                </ButtonIconNew>
              </Box>
              <Box direction="column">
                <KeyValue name="Full name">{formValues.fullName}</KeyValue>
                <KeyValue name="Email">{formValues.email}</KeyValue>
                <KeyValue name="Card number">{cardInfo}</KeyValue>
                <KeyValue name="Country">{countryName}</KeyValue>
                <KeyValue name="State">{formValues.state}</KeyValue>
                <KeyValue name="City">{formValues.city}</KeyValue>
                <KeyValue name="Address">{formValues.address}</KeyValue>
                <KeyValue name="ZIP code">{formValues.zipCode}</KeyValue>
                <KeyValue name="Company">{formValues.companyName}</KeyValue>
              </Box>
            </TileWrapper>
          </Box>
        </BillingTwoColumnLayoutLeft>
        <BillingTwoColumnLayoutRight>
          <Box direction="column" gap="x-large" fullWidth>
            <BillSummary />
            <PaymentsPartner withTile />
          </Box>
        </BillingTwoColumnLayoutRight>
      </BillingTwoColumnLayout>

      <FullScreenModalFooter justify="between">
        <PoweredByStripeBanner />
        <Box gap="medium" justify="end">
          <Button
            variant="secondary"
            onClick={handleBack}
            disabled={isSubmitting}
            startIcon={ArrowLeft}
            analyticsPage={AnalyticsPageBilling.BillingUpgrade}
            analyticsTitle="Go back to first step"
            analyticsProps={{
              location: "Back Button",
            }}
          >
            Back
          </Button>
          <Button
            variant="primary"
            onClick={handleSubmit(submit)}
            loading={isSubmitting}
            disabled={isSubmitting}
          >
            Pay now & Subscribe
          </Button>
        </Box>
      </FullScreenModalFooter>
    </>
  );
};

export default BillingUpgradeSummary;
