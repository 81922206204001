import cx from "classnames";
import { useEffect, useState } from "react";
import {
  StripeCardCvcElementOptions,
  StripeCardExpiryElementOptions,
  StripeCardNumberElementOptions,
} from "@stripe/stripe-js";
import { CardNumberElement, CardCvcElement, CardExpiryElement } from "@stripe/react-stripe-js";

import useTypedContext from "hooks/useTypedContext";
import { ThemeContext } from "views/Theme";

import styles from "./styles.module.css";

type Options = StripeCardNumberElementOptions &
  StripeCardExpiryElementOptions &
  StripeCardCvcElementOptions;

const getComputedOptions = (): Options => {
  const styles = getComputedStyle(document.body);

  return {
    style: {
      base: {
        color: styles.getPropertyValue("--color-default-primary"),
        "::placeholder": {
          color: `${styles.getPropertyValue("--color-default-disabled")}7F`,
        },
      },
      invalid: {
        color: styles.getPropertyValue("--semantic-color-background-decoration-red-emphasized"),
      },
    },
  };
};

const BillingCardForm = () => {
  const { currentTheme } = useTypedContext(ThemeContext);
  const [options, setOptions] = useState<Options>(getComputedOptions);

  useEffect(() => {
    // Wait for the next tick to make sure the styles are applied
    queueMicrotask(() => {
      setOptions(getComputedOptions());
    });
  }, [currentTheme]);

  return (
    <>
      <div className={styles.billingFormGroup}>
        <label className={styles.billingFormLabel} htmlFor="cardNumber">
          Card number*
          <CardNumberElement id="cardNumber" options={options} />
        </label>
      </div>
      <div className={styles.billingFormGroup}>
        <label className={styles.billingFormLabel} htmlFor="expirationDate">
          Expiration date*
          <CardExpiryElement options={options} id="expirationDate" />
        </label>
      </div>
      <div className={cx(styles.billingFormGroup, styles.billingFormGroupHalf)}>
        <label className={styles.billingFormLabel} htmlFor="cvc">
          CVV/CVC*
          <CardCvcElement options={options} id="cvc" />
        </label>
      </div>
    </>
  );
};

export default BillingCardForm;
