import BadgeNext from "ds/components/BadgeNext";

import { StripeSubscriptionStatus } from "../../types";
import { isPaymentFailed, isSubscriptionActive } from "../../utils";
import styles from "./styles.module.css";

type BillingSubscriptionStatusBadgeProps = {
  status: StripeSubscriptionStatus;
};

const BillingSubscriptionStatusBadge = ({ status }: BillingSubscriptionStatusBadgeProps) => {
  if (isSubscriptionActive(status)) {
    return <BadgeNext variant="green" type="strong" text="Active" className={styles.badge} />;
  }

  if (isPaymentFailed(status)) {
    return <BadgeNext variant="red" type="strong" text="Payment failed" className={styles.badge} />;
  }

  return null;
};

export default BillingSubscriptionStatusBadge;
