import { useForm, FormProvider, Controller } from "react-hook-form";
import { NetworkStatus, useQuery } from "@apollo/client";
import { useEffect } from "react";
import isEqual from "lodash-es/isEqual";

import useTypedContext from "hooks/useTypedContext";
import Typography from "ds/components/Typography";
import Box from "ds/components/Box";
import FlashContext from "components/FlashMessages/FlashContext";
import FormLoading from "components/form/components/loading";
import FormField from "ds/components/Form/Field";
import { TooltipModalTitle } from "ds/components/TooltipModal/Title";
import TooltipModalBody from "ds/components/TooltipModal/Body";
import Input from "ds/components/Input";
import { VcsProvider } from "types/generated";
import { AccountContext } from "views/AccountWrapper";
import TextLink from "components/DocumentationSnippets/TextLink";
import Tile from "ds/components/Tile";
import TileWrapper from "ds/components/Tile/Wrapper";
import TileContent from "ds/components/Tile/Content";
import IconTile from "ds/components/IconTile";
import TileTitle from "ds/components/Tile/Title";
import FullScreenModalBody from "ds/components/FullScreenModal/Body";
import CardWrapper from "components/CardWrapper";
import { checkWithMultipleVCSIntegrations } from "utils/vcs";
import { AnalyticsPageStack } from "hooks/useAnalytics/pages/stack";
import MissingDataBanner from "components/MissingDataBanner";
import { getDocsUrl } from "utils/getDocsUrl";
import ReadMoreDocsLink from "components/ReadMoreDocsLink";
import { useFormValidations } from "hooks";

import { StackCreationWizardStep, StackVcsFormFields } from "../types";
import { StackFormContext } from "../context";
import NewStackFooter from "../Footer";
import { VCS_PROVIDERS_IN_SPACE } from "./gql";
import { VcsProviderInSpacesGql } from "./types";
import { useVcsProviderConfig } from "./useVcsConfig";
import TileCheckboxGrid from "../TileCheckboxGrid";
import VcsRepositoryField from "./RepositoryField";
import VcsBranchesField from "./BranchesField";
import VcsRepositoryUrlField from "./RepositoryUrlField";
import Documentation from "./Documentation";
import { getTooltipAnalyticsProps } from "../utils";
import { useNewStackAnalyticsSegementEvent } from "../useNewStackAnalyticsSegementEvent";
import useErrorHandlerNewStack from "../useErrorHandlerNewStack";
import useStackCreationAnalyticsVersion from "../useStackCreationAnalyticsVersion";
import ProjetGlobsField from "./ProjectGlobsField";
import VcsIntegrationField from "./IntegrationField";

const processValuesBeforeSubmit = (values: StackVcsFormFields): StackVcsFormFields => {
  const projectRoot = values.projectRoot?.trim();

  const additionalProjectGlobs = values.additionalProjectGlobs
    ?.map((projectGlob) => {
      return {
        value: projectGlob.value.trim(),
      };
    })
    .filter((projectGlob) => projectGlob.value);

  return {
    ...values,
    projectRoot,
    additionalProjectGlobs,
  };
};

const NewStackVcs = () => {
  const { viewer } = useTypedContext(AccountContext);
  const { onError } = useTypedContext(FlashContext);
  const analyticsVersion = useStackCreationAnalyticsVersion();
  const trackSegmentEvent = useNewStackAnalyticsSegementEvent();

  const { currentStep, updateStepData, formData, setInternalFormData } =
    useTypedContext(StackFormContext);
  const stackDetailsStepData = formData[StackCreationWizardStep.Details];
  const stepData = formData[StackCreationWizardStep.Vcs];

  const builderForm = useForm<StackVcsFormFields>({
    defaultValues: {
      ...stepData,
    },
    mode: "onChange",
  });

  const { control, setValue, register, watch, reset } = builderForm;
  const runFormValidations = useFormValidations(builderForm);

  const { data, loading, error, refetch, networkStatus } = useQuery<VcsProviderInSpacesGql>(
    VCS_PROVIDERS_IN_SPACE,
    {
      variables: {
        spaceId: stackDetailsStepData.space,
      },
      onError,
      skip: !stackDetailsStepData.space,
    }
  );

  useErrorHandlerNewStack(error);

  const newStepData = watch();

  const processStepData = () => {
    const preparedData = processValuesBeforeSubmit(newStepData);
    return updateStepData(currentStep, preparedData);
  };

  const isDataChanged = !isEqual(
    {
      ...newStepData,
      additionalProjectGlobs: newStepData.additionalProjectGlobs?.filter(({ value }) => value),
    },
    stepData
  );

  const vcsProviderConfig = useVcsProviderConfig(data?.providersInSpace);

  const withMultipleVCSIntegrations = checkWithMultipleVCSIntegrations(newStepData.provider);

  const waitForVCSIntegrationId = withMultipleVCSIntegrations && !newStepData.vcsIntegrationId;

  const handleChangeProvider = (fieldValue: VcsProvider | undefined, type: VcsProvider) => () => {
    if (type !== fieldValue) {
      trackSegmentEvent("Integration type", {
        integration: type,
        version: analyticsVersion,
      });

      reset({
        repository: "",
        branch: "",
        projectRoot: "",
        repositoryName: "",
        repositoryURL: undefined,
        provider: type,
        additionalProjectGlobs: [],
        vcsIntegrationId: undefined,
      });

      setInternalFormData((state) => ({ ...state, vcsIntegrationLabel: undefined }));
    }
  };

  useEffect(() => {
    if (!newStepData.provider) {
      setValue("provider", vcsProviderConfig[0]?.type);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vcsProviderConfig]);

  if (loading && networkStatus !== NetworkStatus.refetch) {
    return <FormLoading />;
  }

  return (
    <>
      <FullScreenModalBody>
        <Typography tag="h2" variant="p-t4" align="center">
          Connect to source code
        </Typography>
        <Typography tag="p" variant="p-body2" align="center" color="secondary" margin="small 0 0 0">
          {viewer?.admin && (
            <>
              Spacelift supports multiple source code providers. You can connect one on the{" "}
              <TextLink
                analyticsPage={AnalyticsPageStack.StackNew}
                analyticsProps={{ version: analyticsVersion }}
                analyticsTitle="Source code link click"
                to={"/vcs/integrations"}
              >
                Source Code Integrations
              </TextLink>{" "}
              page.
            </>
          )}
          {!viewer?.admin && (
            <>
              Spacelift supports multiple source code providers. <br />
              Please reach out to one of your friendly account admins to set up one.
            </>
          )}
        </Typography>

        {!data && (
          <Box direction="column" margin="large 0 0 0">
            <MissingDataBanner
              refreshLoading={loading && networkStatus === NetworkStatus.refetch}
              refreshHandler={refetch}
            />
          </Box>
        )}

        {data && (
          <FormProvider {...builderForm}>
            <Controller
              name="provider"
              control={control}
              render={({ field }) => (
                <Box direction="column" gap="x-large" margin="x-large 0 0 0">
                  <TileCheckboxGrid>
                    {vcsProviderConfig.map(({ name, logo, type }) =>
                      vcsProviderConfig.length === 1 ? (
                        <TileWrapper key={type}>
                          <TileContent direction="row" align="center">
                            <IconTile icon={logo} />
                            <TileTitle>{name}</TileTitle>
                          </TileContent>
                        </TileWrapper>
                      ) : (
                        <Tile
                          key={type}
                          selected={field.value === type}
                          title={name}
                          icon={logo}
                          onClick={handleChangeProvider(field.value, type)}
                          indicator="radio"
                        />
                      )
                    )}
                  </TileCheckboxGrid>
                </Box>
              )}
            />
            <Box direction="column" margin="x-large 0 large 0" gap="large">
              {withMultipleVCSIntegrations &&
                newStepData.provider &&
                stackDetailsStepData.space && (
                  <VcsIntegrationField
                    provider={newStepData.provider}
                    spaceId={stackDetailsStepData.space}
                  />
                )}

              {newStepData.provider &&
                newStepData.provider !== VcsProvider.Git &&
                !waitForVCSIntegrationId && <VcsRepositoryField />}
              {newStepData.provider === VcsProvider.Git && <VcsRepositoryUrlField />}
              {newStepData.repository && !waitForVCSIntegrationId && <VcsBranchesField />}

              <FormField
                label="Project root"
                isOptional
                {...getTooltipAnalyticsProps("Source Code", "Project root", {
                  provider: newStepData.provider,
                  version: analyticsVersion,
                })}
                tooltipInfoVariant="modal"
                tooltipInfo={
                  <>
                    <TooltipModalTitle>Project root</TooltipModalTitle>
                    <TooltipModalBody align="start">
                      Project root is the optional directory relative to the workspace root
                      containing the entrypoint to the Stack.
                      <ReadMoreDocsLink
                        docsUrl={getDocsUrl("/concepts/stack/stack-settings#project-root")}
                      />
                    </TooltipModalBody>
                  </>
                }
                noMargin
              >
                {({ ariaInputProps }) => (
                  <Input
                    placeholder="Defaults to root of the repo"
                    {...register("projectRoot")}
                    {...ariaInputProps}
                  />
                )}
              </FormField>
              <CardWrapper variant="filled" gap="large" direction="column">
                <ProjetGlobsField />
              </CardWrapper>
            </Box>
          </FormProvider>
        )}
      </FullScreenModalBody>
      <NewStackFooter
        isDataChanged={isDataChanged}
        processStepData={processStepData}
        documentationLink={getDocsUrl("/concepts/stack/creating-a-stack#integrate-vcs")}
        documentationTitle="Integrate source code"
        documentationBody={<Documentation />}
        runFormValidations={runFormValidations}
      />
    </>
  );
};

export default NewStackVcs;
